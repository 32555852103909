<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-row type="flex" justify="center" class="bg">
      <el-col :span="12">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <h2> Uso dei cookie</h2>
              <p>
                Il &quot;Sito&quot; (sunnyonline.it) utilizza i Cookie per rendere i propri servizi semplici e efficienti per
                l’utenza che visiona le pagine di sunnyonline.it. Gli utenti che visionano il Sito, vedranno inserite
                delle quantità minime di informazioni nei dispositivi in uso, che siano computer e periferiche
                mobili, in piccoli file di testo denominati “cookie” salvati nelle directory utilizzate dal browser web
                dell’Utente. Vi sono vari tipi di cookie, alcuni per rendere più efficace l’uso del Sito, altri per
                abilitare determinate funzionalità.
              </p>
              <p>Analizzandoli in maniera particolareggiata i nostri cookie permettono di:</p>
              <ul>
                <li> memorizzare le preferenze inserite; </li>
                <li> evitare di reinserire le stesse informazioni più volte durante la visita quali ad esempio nome
                  utente e password; </li>
                <li> analizzare l’utilizzo dei servizi e dei contenuti forniti da sunnyonline per ottimizzarne
                  l’esperienza di navigazione e i servizi offerti. </li>
              </ul>

              <h3> Tipologie di Cookie </h3>

              <h4>Cookie tecnici</h4>
              <p>
                Questa tipologia di cookie permette il corretto funzionamento di alcune sezioni del Sito. Sono di
                due categorie: persistenti e di sessione.
              </p>
              <ul>
                <li>
                  <p>persistenti:</p>
                  <p>una volta chiuso il browser non vengono distrutti ma rimangono fino ad una data di
                    scadenza preimpostata.</p>
                </li>
                <li>
                  <p>di sessione:</p>
                  <p>vengono distrutti ogni volta che il browser viene chiuso.</p>
                </li>
              </ul>
              <p>
                Questi cookie, inviati sempre dal nostro dominio, sono necessari a visualizzare correttamente il sito
                e in relazione ai servizi tecnici offerti, verranno quindi sempre utilizzati e inviati, a meno che
                l’utenza non modifichi le impostazioni nel proprio browser (inficiando così la visualizzazione delle
                pagine del sito).
              </p>

              <h4>Cookie analitici</h4>
              <p>
                I cookie in questa categoria vengono utilizzati per collezionare informazioni sull’uso del sito.
                Sunnyonline userà queste informazioni in merito ad analisi statistiche anonime al fine di migliorare
                l’utilizzo del Sito e per rendere i contenuti più interessanti e attinenti ai desideri dell’utenza. Questa
                tipologia di cookie raccoglie dati in forma anonima sull’attività dell’utenza e su come è arrivata sul
                Sito. I cookie analitici sono inviati dal Sito Stesso o da domini di terze parti.
              </p>

              <h4> Cookie di analisi di servizi di terze parti </h4>
              <p>
                Questi cookie sono utilizzati al fine di raccogliere informazioni sull’uso del Sito da parte degli
                utenti in forma anonima quali: pagine visitate, tempo di permanenza, origini del traffico di
                provenienza, provenienza geografica, età, genere e interessi ai fini di campagne di marketing.
                Questi cookie sono inviati da domini di terze parti esterni al Sito.
              </p>

              <h4> Cookie per integrare prodotti e funzioni di software di terze parti </h4>
              <p>
                Questa tipologia di cookie integra funzionalità sviluppate da terzi all’interno delle pagine del Sito
                come le icone e le preferenze espresse nei social network al fine di condivisione dei contenuti del
                sito o per l’uso di servizi software di terze parti (come i software per generare le mappe e ulteriori
                software che offrono servizi aggiuntivi). Questi cookie sono inviati da domini di terze parti e da siti
                partner che offrono le loro funzionalità tra le pagine del Sito.
              </p>

              <h4> Cookie di profilazione </h4>
              <p>
                Sono quei cookie necessari a creare profili utenti al fine di inviare messaggi pubblicitari in linea con
                le preferenze manifestate dall’utente all’interno delle pagine del Sito.
              </p>
              <p>
                Per tutte le altre tipologie di cookie il consenso può essere espresso dall’Utente con una o più di una
                delle seguenti modalità:
              </p>
              <ul>
                <li> Mediante specifiche configurazioni del browser utilizzato o dei relativi programmi
                  informatici utilizzati per navigare le pagine che compongono il Sito.
                </li>
                <li>
                  Mediante modifica delle impostazioni nell’uso dei servizi di terze parti.
                </li>
              </ul>
              <p>
                Entrambe queste soluzioni potrebbero impedire all’utente di utilizzare o visualizzare parti del Sito.
              </p>

              <h4> Siti Web e servizi di terze parti </h4>
              <p>
                Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa
                sulla privacy che può essere diverse da quella adottata da sunnyonline e che quindi non risponde di
                questi siti.
              </p>

              <h4> Come disabilitare i cookie mediante configurazione del browser </h4>
              <p> Se desideri approfondire le modalità con cui il tuo browser memorizza i cookies durante la tua
                navigazione, ti invitiamo a seguire questi link sui siti dei rispettivi fornitori. </p>
              <ul>
                <li> <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie"> Mozilla Firefox </a></li>
                <li> <a href="https://support.google.com/chrome/answer/95647?hl=it"> Google Chrome </a></li>
                <li> <a href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"> Internet Explorer </a></li>
                <li> <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"> Safari 8 Yosemite </a></li>
                <li> <a href="https://support.apple.com/it-it/HT201265"> Safari su iPhone, iPad, o iPod touch </a></li>
              </ul>

            </div>
          </template>
        </el-card>
      </el-col>
    </el-row>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "CookiePolicy",
  components: {Header, Footer},
}
</script>

<style scoped>
  .bg {
    background: #ededed;
    width: 100%;
    align-items: center;
  }

</style>
