<template>
  <div class="header-wrapper">
    <div class="pointer" style="display: flex; align-items: center" @click="goTo(`dashboard`)">
      <el-avatar src="logo_default_sunny.PNG" shape="square" scale-down></el-avatar>
      <span style="margin-left: 5px">Home</span>
    </div>

    <login-dropdown></login-dropdown>
  </div>
</template>

<script>

import LoginDropdown from "./LoginDropdown";

export default {
  name: "Header",
  components: {LoginDropdown},
  methods: {
    goTo(name) {
      this.$router.push({name})
    },
  },
}
</script>

<style scoped>

.header-wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
